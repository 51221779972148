<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Agents
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Agent Name"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="code"
                  placeholder="Agent Code"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="code = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="contact"
                  placeholder="Mobile No."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="contact = '';"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="email"
                  placeholder="Email Address"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="email = '';"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-agents"
                ref="filter_dropdown_all_agents"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <agent-filters
                  :agency-filter.sync="agencyFilter"
                  :status-filter.sync="statusFilter"
                  :agent-type-filter.sync="agentTypeFilter"
                  :agency-options="agencyOptions"
                  :status-options="statusOptions"
                  :agent-options="agentTypeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="agencyFilter.length || statusFilter.length || agentTypeFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="stat in statusFilter"
                  :key="stat"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeStatusFilter(stat)"
                  >
                    <span class="text-capitalize">{{ stat }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="agencyVal in agencyFilter"
                  :key="agencyVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeAgencyFilter(agencyVal)"
                  >
                    <span class="text-capitalize">{{ resolveAgencyName(agencyVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="agentVal in agentTypeFilter"
                  :key="agentVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeAgentTypeFilter(agentVal)"
                  >
                    <span class="text-capitalize">{{ agentVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refAgentsTable"
        class="position-relative event_list min-height-200"
        :items="fetchAgents"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Agent')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'agency-agents-show', params: { id: data.item._id } }"
            >
              {{ data.item.name }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="text-bold align-middle detail-view-id not-button"
            >
              {{ data.item.name }}
            </b-button>
            <br>
            <span>{{ data.item.code }}</span>
          </div>
        </template>

        <template #cell(agencyName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.agencyName }}</span>
            <br>
            <span>
              {{ `(${data.item.agencyCode}) - ${data.item.agencyState ? data.item.agencyState : ''}` }}
            </span>
          </div>
        </template>

        <template #cell(personalContact)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.personalContact }}</span>
            <br>
            <span>
              {{ data.item.email }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(emailVerified)="data">
          <span>{{ data.item.emailVerified ? 'Yes' : 'No' }}</span>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ getCreatedBy(data.item) }}</span>
            <br>
            <span class="">{{ getCreatedAt(data.item) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'Agent')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Agent')"
              :to="{ name: 'agency-agents-show', params: { id: data.item._id } }"
            >
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('update', 'Agent')"
              :to="{ name: 'agency-agents-edit', params: { id: data.item._id } }"
            >
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalAgent > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-show="totalAgent > agentsData.length"
            v-model="currentPage"
            :total-rows="totalAgent"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, BButton, BFormTag,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAgentList from './useAgentList'
import agentListStoreModule from './agentListStoreModule'
import AgentFilters from './AgentFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    AgentFilters,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      agencyOptions: [],
      agentTypeOptions: [
        { label: 'Nirvana SG Users', value: 'Nirvana SG Users' },
        { label: 'Non Nirvana SG Users', value: 'Non Nirvana SG Users' },
      ],
      statusOptions: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Expired', value: 'Expired' },
      ],

      required,
    }
  },

  beforeMount() {
    this.$http.get('customer/agents/respond-with/agency-options')
      .then(response => {
        this.agencyOptions = response.data.agencies ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    getCreatedBy(item) {
      if (item.createdByNvs) {
        return item.createdByNvs.name
      }
      if (item.createdBy) {
        return item.createdBy
      }

      return ''
    },
    getCreatedAt(item) {
      if (item.createdAtNvs) {
        return this.dateFormatWithTime(item.createdAtNvs)
      }
      if (item.createdAt) {
        return this.dateFormatWithFormatNTZ(item.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    hideFilter() {
      this.$refs.filter_dropdown_all_agents.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_agents.show(true)
    },
    removeAgencyFilter(item) {
      const index = this.agencyFilter.indexOf(item)
      if (index > -1) {
        this.agencyFilter.splice(index, 1)
      }
    },
    resolveAgencyName(item) {
      // eslint-disable-next-line eqeqeq
      const index = this.agencyOptions.find(i => i.agencyID == item)
      if (index) {
        return index.name
      }

      return ''
    },
    removeStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    removeAgentTypeFilter(item) {
      const index = this.agentTypeFilter.indexOf(item)
      if (index > -1) {
        this.agentTypeFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.agencyFilter = []
      this.statusFilter = []
      this.agentTypeFilter = []
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const AGENT_APP_STORE_MODULE_NAME = 'app-all-agents'

    // Register module
    if (!store.hasModule(AGENT_APP_STORE_MODULE_NAME)) store.registerModule(AGENT_APP_STORE_MODULE_NAME, agentListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENT_APP_STORE_MODULE_NAME)) store.unregisterModule(AGENT_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchAgents,
      tableColumns,
      perPage,
      currentPage,
      totalAgent,
      agentsData,
      dataMeta,
      perPageOptions,
      searchQuery,
      contact,
      code,
      email,
      sortBy,
      isSortDirDesc,
      refAgentsTable,
      refetchData,

      // Extra Filters
      agencyFilter,
      statusFilter,
      agentTypeFilter,
    } = useAgentList()

    return {
      // Sidebar
      fetchAgents,
      tableColumns,
      perPage,
      currentPage,
      totalAgent,
      dataMeta,
      perPageOptions,
      searchQuery,
      contact,
      code,
      email,
      sortBy,
      isSortDirDesc,
      refAgentsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      agentsData,

      // Filter
      avatarText,
      canViewThisAction,

      // Extra Filters
      agencyFilter,
      statusFilter,
      agentTypeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.dropdown__left-align-adjust{
  ul{
     left: 100% !important;
  }
}
.dropdown__left-align-adjust-mobile{
  ul{
     left: 50% !important;
  }
}
</style>
