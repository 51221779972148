import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAgentList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refAgentsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'agent name/code',
      sortable: true,
    },
    {
      key: 'agencyName',
      label: 'agency',
      sortable: true,
    },
    {
      key: 'personalContact',
      label: 'contact',
      sortable: true,
    },
    {
      key: 'status',
      label: 'status',
      sortable: true,
    },
    {
      key: 'emailVerified',
      label: 'Nirvana SG user',
      sortable: true,
    },
    {
      key: 'remark',
      label: 'remark',
      sortable: false,
    },

    {
      key: 'createdAt',
      label: 'Created By',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalAgent = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const contact = ref(route.query.contact ?? '')
  const code = ref(route.query.code ?? '')
  const email = ref(route.query.email ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')

  const agencyFilter = ref([])
  if (Array.isArray(route.query.agency)) {
    agencyFilter.value = route.query.agency
  } else if (route.query.agency) {
    agencyFilter.value = [route.query.agency]
  }
  const agentTypeFilter = ref([])
  if (Array.isArray(route.query.agentType)) {
    agentTypeFilter.value = route.query.agentType
  } else if (route.query.agentType) {
    agentTypeFilter.value = [route.query.agentType]
  }

  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const agentsData = ref([])
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refAgentsTable.value ? refAgentsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAgent.value,
    }
  })

  const refetchData = () => {
    refAgentsTable.value.refresh()
  }

  let timer = null
  watch([searchQuery, contact, code, email, agencyFilter, statusFilter, agentTypeFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      refetchData()
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchAgents = (ctx, callback) => {
    store
      .dispatch('app-all-agents/fetchAgents', {
        search: searchQuery.value,
        contact: contact.value,
        code: code.value,
        email: email.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        agency: agencyFilter.value,
        status: statusFilter.value,
        agentType: agentTypeFilter.value,
      },
      { root: true })
      .then(response => {
        const { agents, totalAgents } = response.data
        agentsData.value = agents
        callback(agents)
        totalAgent.value = totalAgents
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              contact: contact.value,
              code: code.value,
              email: email.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              agency: agencyFilter.value,
              status: statusFilter.value,
              agentType: agentTypeFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchAgents,
    tableColumns,
    perPage,
    currentPage,
    totalAgent,
    dataMeta,
    perPageOptions,
    searchQuery,
    contact,
    code,
    email,
    sortBy,
    isSortDirDesc,
    refAgentsTable,
    refetchData,
    agentsData,

    // Extra Filters
    agencyFilter,
    statusFilter,
    agentTypeFilter,
  }
}
