import axios from '@axios'

// const makeRequestCreator = () => {
//   let call
//   return url => {
//     if (call) {
//       call.cancel('Only one request allowed at a time.')
//     }
//     call = axios.CancelToken.source()
//     return axios.get(url, {
//       cancelToken: call.token,
//     })
//   }
// }
// const get = makeRequestCreator()

// console.log((get))

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAgents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('customer/agents', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
